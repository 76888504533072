import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import * as ErrorDiagnostics from '~/error';
import { AppError } from '~/error';
import {
    AppointmentContactType,
    CalendarEventListDocument,
    fragmentToAppointment,
    ModifyCalendarEventDocument,
    ReserveAppointmentDocument
} from '~/types';
import { toISODateTimeString } from '~/utils/date';

export function useAppointment(json: JSONString, calendarEventId?: ID) {
    const appointment = useMemo(() => {
        try {
            return fragmentToAppointment(JSON.parse(json));
        } catch (error: unknown) {
            ErrorDiagnostics.error(`${error}: ${json}`);
            throw error;
        }
    }, [json]);

    const handleError = useErrorHandler();
    const [reserveAppointment] = useMutation(ReserveAppointmentDocument);

    const handleReserveAppointment = useCallback(
        async (contactType: AppointmentContactType) => {
            try {
                const { data } = await reserveAppointment({
                    variables: {
                        input: {
                            startTime: toISODateTimeString(appointment.start),
                            contactType,
                            duration: appointment.duration,
                            meta: appointment.meta,
                            resource: appointment.resource
                        }
                    },
                    refetchQueries: [{ query: CalendarEventListDocument }]
                });

                const { id, acuteId } = data?.reserveAppointment?.calendarEvent ?? {};
                return acuteId ?? id;
            } catch (error: unknown) {
                if (error instanceof Error) {
                    handleError(
                        new AppError(error, 'error.make-appointment-failed', {
                            onClose: () => {},
                            name: 'error-overlay.go_back'
                        })
                    );
                } else {
                    handleError(error);
                }
            }
        },
        [
            appointment.duration,
            appointment.meta,
            appointment.resource,
            appointment.start,
            handleError,
            reserveAppointment
        ]
    );

    const [modifyCalendarEvent] = useMutation(ModifyCalendarEventDocument);

    const handleModifyCalendarEvent = useCallback(
        async (contactType?: AppointmentContactType) => {
            try {
                if (calendarEventId) {
                    const { data } = await modifyCalendarEvent({
                        variables: {
                            input: {
                                eventId: calendarEventId,
                                startTime: toISODateTimeString(appointment.start),
                                contactType,
                                duration: appointment.duration,
                                meta: appointment.meta,
                                resource: appointment.resource
                            }
                        },
                        refetchQueries: [{ query: CalendarEventListDocument }]
                    });

                    const { id, acuteId } = data?.modifyCalendarEvent?.calendarEvent ?? {};
                    return acuteId ?? id;
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    handleError(
                        new AppError(err, 'error.modify-appointment-failed', {
                            onClose: () => {},
                            name: 'error-overlay.go_back'
                        })
                    );
                } else {
                    handleError(err);
                }
            }
        },
        [
            appointment.duration,
            appointment.meta,
            appointment.resource,
            appointment.start,
            calendarEventId,
            handleError,
            modifyCalendarEvent
        ]
    );
    return {
        appointment,
        reserveAppointment: handleReserveAppointment,
        modifyCalendarEvent: handleModifyCalendarEvent
    };
}
