import { addDays, startOfToday } from 'date-fns';
import { useMemo } from 'react';

import { useLoadingQuery } from '~/hooks/loading-query';
import { CalendarEventListDocument, CalendarEventListItemFragment, fragmentToCalendarEvent } from '~/types';
import { relayConnectionReduce } from '~/utils';
import { toISODateTimeString } from '~/utils/date';

export function useCalendarEventList(isPast: boolean = false) {
    // Keep start and end time parameters stable for the day for caching to work
    // Also, keep even ended appointments for the current day visible
    const startTime = startOfToday();
    const endTime = addDays(startTime, isPast ? -30 : 120);

    const { data, error, loading, loadingInitial } = useLoadingQuery(CalendarEventListDocument, {
        variables: {
            start: toISODateTimeString(isPast ? endTime : startTime),
            end: toISODateTimeString(isPast ? startTime : endTime)
        }
    });

    const calendarEvents = useMemo(
        () =>
            relayConnectionReduce<CalendarEventListItemFragment>(data?.root?.calendarEvents)
                ?.map(fragmentToCalendarEvent)
                .sort((a, b) =>
                    isPast ? b.start.getTime() - a.start.getTime() : a.start.getTime() - b.start.getTime()
                ),
        [data?.root?.calendarEvents, isPast]
    );

    return { loading, loadingInitial, error, calendarEvents };
}
