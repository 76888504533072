import { useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';

import { Alert } from '~/components/alert';
import { useAnalytics } from '~/contexts/analytics';
import { useAuth } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import * as ErrorDiagnostics from '~/error';
import { inputToDisplayStates } from '~/hooks/pin-code';
import { AuthSMSAuthenticateNavigation } from '~/screens/auth/auth-sms-authentication';

export const SMS_CODE_LENGTH = 6;
const SMS_CODE_MAX_RETRY_COUNT = 3;
const SMS_CODE_MAX_RESEND_COUNT = 3;

export type SMSCodeAuthenticationHookProps = {
    tokenFromEmail: string;
};

export function useSMSCodeAuthentication(props: SMSCodeAuthenticationHookProps) {
    const { tokenFromEmail } = props;

    const { formatMessage } = useIntl();
    const { track } = useAnalytics();
    const { remote } = useAuth();
    const { reset } = useNavigation<AuthSMSAuthenticateNavigation>();

    const [feedbackMessage, setFeedbackMessage] = useState<string>(formatMessage('auth-sms-authenticate.enter-code'));
    const [inputValue, setInputValue] = useState<string>('');
    const [retryCount, setRetryCount] = useState<number>(0);
    const [resendCount, setResendCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    /**
     * Show an alert to the user because of too many unsuccessful retries
     * When the user presses OK send them back to verification check
     */
    const startOver = useCallback(() => {
        track({ type: 'auth-sms', action: 'restart' });

        Alert.alert(
            formatMessage('auth-sms-authenticate.invalid-code-restart.title'),
            formatMessage('auth-sms-authenticate.invalid-code-restart.description'),
            [
                {
                    text: formatMessage('ok'),
                    onPress: () => {
                        reset({
                            index: 1,
                            routes: [{ name: 'intro' }, { name: 'verification-check' }]
                        });
                    }
                }
            ]
        );
    }, [formatMessage, reset, track]);

    /**
     * Fire code validation API request
     */
    const validateCode = useCallback(
        (code: string) => {
            if (code.length !== SMS_CODE_LENGTH || loading) {
                return;
            }

            ErrorDiagnostics.log('Signing in with SMS code');
            track({ type: 'auth-sms', action: 'success' });

            setLoading(true);
            setFeedbackMessage(formatMessage('auth-sms-authenticate.verifying-code'));

            remote
                .verifySMSCode(tokenFromEmail, code)
                .then(result => {
                    if (result.success) {
                        result.commit();
                    } else if (retryCount <= SMS_CODE_MAX_RETRY_COUNT) {
                        setLoading(false);
                        setRetryCount(retryCount + 1);
                        setInputValue('');
                        setFeedbackMessage(formatMessage('auth-sms-authenticate.invalid-code-retry'));
                    } else {
                        startOver();
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        },
        [loading, track, formatMessage, remote, tokenFromEmail, retryCount, startOver]
    );

    /**
     * Send a new SMS code
     */
    const resendSMS = useCallback(async () => {
        if (loading) {
            return;
        }

        if (resendCount >= SMS_CODE_MAX_RESEND_COUNT) {
            startOver();
            return;
        }

        ErrorDiagnostics.log('Resend the SMS code');
        track({ type: 'auth-sms', action: 'resend' });

        setLoading(true);
        setInputValue('');
        setResendCount(resendCount + 1);

        remote
            .resendSMSCode(tokenFromEmail)
            .then(() => {
                setLoading(false);

                Alert.alert(formatMessage('auth-sms-authenticate.resend-code.success'), undefined, [
                    {
                        text: formatMessage('ok'),
                        onPress: () => {}
                    }
                ]);
            })
            .catch(() => {
                setLoading(false);

                Alert.alert(formatMessage('error'), formatMessage('auth-sms-authenticate.resend-code.error'), [
                    {
                        text: formatMessage('ok'),
                        onPress: () => {}
                    }
                ]);
            });
    }, [formatMessage, loading, remote, resendCount, startOver, tokenFromEmail, track]);

    /**
     * Store the input value to state
     * When the value is of expected length -> fire validation API request
     */
    const onInput = useCallback(
        (value: number) => {
            const newValue = inputValue + `${value}`;

            setInputValue(newValue);

            if (newValue.length === SMS_CODE_LENGTH) {
                validateCode(newValue);
            }
        },
        [inputValue, validateCode]
    );

    /**
     * Backspace
     */
    const onDelete = useCallback(() => {
        if (inputValue.length > 0) {
            setInputValue(inputValue.substring(0, inputValue.length - 1));
        }
    }, [inputValue]);

    return {
        inputValue,
        onInput,
        onDelete,
        loading,
        resendSMS,
        states: inputToDisplayStates(inputValue, SMS_CODE_LENGTH),
        message: feedbackMessage
    };
}
