import { BottomSheetSectionList } from '@gorhom/bottom-sheet';
import { addHours, startOfHour } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { View, SectionListRenderItemInfo, SectionListData } from 'react-native';
import styled from 'styled-components/native';

import { AppointmentListRow } from '~/components/appointment-list/appointment-list-row';
import { Color } from '~/components/color';
import { Text } from '~/components/text';
import { FormattedTime } from '~/contexts/intl';
import { Appointment } from '~/types';
import { isWeb } from '~/utils';

export type AppointmentListProps = {
    appointments?: readonly Appointment[];
    onOpenAppointment: (appointment: Appointment) => void;
};

const BUSINESS_TIMEZONE = 'Europe/Helsinki';

const ItemSeparator = styled.View`
    height: 1px;
    width: 100%;

    background-color: ${Color.LINE_DEFAULT};
    margin-left: 16px;
`;

type AppointmentSection = {
    hours: [Date, Date];
    data: Appointment[];
};

export const SectionHeader = styled.View`
    background-color: ${Color.BACKGROUND_DEFAULT};

    padding: 16px 0px 0px;
    border-color: ${Color.LINE_DEFAULT};
`;

export const SectionTitle = styled.View`
    padding-top: 6px;
    padding-bottom: 16px;
    margin-left: 16px;
    border-bottom-width: 1px;
    border-color: ${Color.LINE_DEFAULT};
`;

export const AppointmentList: React.FC<AppointmentListProps> = props => {
    const { appointments = [], onOpenAppointment } = props;

    const renderSectionHeader = useCallback(
        (info: { section: SectionListData<Appointment, AppointmentSection> }) => (
            <SectionHeader>
                <SectionTitle>
                    <Text.SUBTITLE_1>
                        <FormattedTime hour="2-digit" value={info.section.hours[0]} timeZone={BUSINESS_TIMEZONE} />–
                        <FormattedTime hour="2-digit" value={info.section.hours[1]} timeZone={BUSINESS_TIMEZONE} />
                    </Text.SUBTITLE_1>
                </SectionTitle>
            </SectionHeader>
        ),
        []
    );
    const renderItem = useCallback(
        (info: SectionListRenderItemInfo<Appointment, AppointmentSection>) => (
            <View key={info.item.id}>
                <AppointmentListRow appointment={info.item} onPress={onOpenAppointment} />
            </View>
        ),
        [onOpenAppointment]
    );

    const sections = useMemo<AppointmentSection[]>(() => {
        return appointments?.reduce((acc, appointment) => {
            const startHour = startOfHour(appointment.start);
            const slot = acc.find(section => section.hours[0].getTime() === startHour.getTime());

            if (slot) {
                slot.data = [...slot.data, appointment];
            } else {
                acc = [...acc, { hours: [startHour, addHours(startHour, 1)], data: [appointment] }];
            }

            return acc;
        }, [] as AppointmentSection[]);
    }, [appointments]);

    if (isWeb()) {
        return (
            <>
                {sections.map((section: AppointmentSection) => {
                    return (
                        <View key={`AppointmentSection-${section.hours[0].toLocaleTimeString()}`}>
                            {renderSectionHeader({ section })}
                            {section.data.map((appointment: Appointment) => {
                                return (
                                    <AppointmentListRow
                                        appointment={appointment}
                                        onPress={onOpenAppointment}
                                        key={appointment.id}
                                    />
                                );
                            })}
                        </View>
                    );
                })}
            </>
        );
    } else {
        return (
            <BottomSheetSectionList<Appointment, AppointmentSection>
                sections={sections}
                keyExtractor={appointment => appointment.id}
                renderItem={renderItem}
                renderSectionHeader={renderSectionHeader}
                ItemSeparatorComponent={ItemSeparator}
            />
        );
    }
};
