import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import React, { useCallback } from 'react';

import HeroImage from '~/assets/images/code-envelope.svg';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { AppNavigatorParamList, UnauthenticatedNavigatorParamList } from '~/navigator/app-navigator';
import { AuthInstruction } from '~/screens/auth/auth-instruction';

export type Props = {
    messageId: string;
};

export const Info: React.FC<Props> = ({ messageId }) => {
    const { formatMessage } = useIntl();

    return (
        <FormattedMessage
            id={messageId}
            values={{
                terms: (value: string) => (
                    <Text.SMALL_BUTTON_LABEL
                        style={{ textDecorationLine: 'underline' }}
                        onPress={async () => {
                            await Linking.openURL(formatMessage('auth-accept-terms.terms'));
                        }}
                    >
                        {value}
                    </Text.SMALL_BUTTON_LABEL>
                ),
                privacy: (value: string) => (
                    <Text.SMALL_BUTTON_LABEL
                        style={{ textDecorationLine: 'underline' }}
                        onPress={async () => {
                            await Linking.openURL(formatMessage('auth-accept-terms.privacy'));
                        }}
                    >
                        {value}
                    </Text.SMALL_BUTTON_LABEL>
                )
            }}
        />
    );
};

type AuthEmailSignupNavigation = StackNavigationProp<AppNavigatorParamList>;
type AuthEmailSignupRouteProp = RouteProp<UnauthenticatedNavigatorParamList, 'email-signup-intro'>;

export const AuthEmailSignupIntro: React.FC = () => {
    const { navigate, canGoBack, goBack } = useNavigation<AuthEmailSignupNavigation>();
    const { params } = useRoute<AuthEmailSignupRouteProp>();
    const { formatMessage } = useIntl();

    const next = useCallback(() => navigate('email-signup-form', { token: params.token }), [navigate, params.token]);

    return (
        <AuthInstruction
            image={<HeroImage />}
            title={formatMessage('auth-email-signup-intro.title')}
            description={formatMessage('auth-email-signup-intro.description')}
            info={<Info messageId="auth-accept-terms-upon-continue" />}
            buttonLabel={formatMessage('auth-intro.button.title')}
            onButtonPress={next}
            backButtonLabel={formatMessage('previous')}
            backOnButtonPress={() => (canGoBack() ? goBack() : navigate('main'))}
        />
    );
};
