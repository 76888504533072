import React from 'react';

import { FormattedMessage, FormattedTime, useIntl } from '~/contexts/intl';
import { isUserInBusinessTimezone } from '~/utils/timezone';

const BUSINESS_TIMEZONE = 'Europe/Helsinki';

export type TimeProps = {
    value?: Date;
};

export function Time(props: TimeProps) {
    const { value } = props;
    const { locale } = useIntl();

    if (!value) {
        return <FormattedMessage id="time.empty" />;
    }

    const formattedTime = <FormattedTime hour="numeric" minute="numeric" value={value} />;

    const dateTimeFormat = new Intl.DateTimeFormat(locale, {
        timeZone: BUSINESS_TIMEZONE,
        hour: 'numeric',
        minute: 'numeric'
    });

    if (isUserInBusinessTimezone()) {
        return formattedTime;
    } else {
        return (
            <>
                {dateTimeFormat.format(new Date(value))} {getHelsinkiTimezone()}
            </>
        );
    }
}

export function isHelsinkiInEEST(date: Date = new Date()): boolean {
    const helsinkiDate = new Date(date.toLocaleString('en-US', { timeZone: BUSINESS_TIMEZONE }));

    const offset = -helsinkiDate.getTimezoneOffset();

    return offset === 180;
}

export function getHelsinkiTimezone(date: Date = new Date()): 'EET' | 'EEST' {
    return isHelsinkiInEEST(date) ? 'EEST' : 'EET';
}
