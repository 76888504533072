import {
    createNavigationContainerRef,
    getStateFromPath,
    LinkingOptions,
    NavigationState,
    PartialState,
    Route
} from '@react-navigation/native';
import * as Linking from 'expo-linking';
import React, { PropsWithChildren, useCallback, useContext, useMemo, useRef } from 'react';

import { useAnalytics } from '~/contexts/analytics';
import { useAuth } from '~/contexts/auth';
import * as ErrorDiagnostics from '~/error';
import { AppNavigatorParamList } from '~/navigator/app-navigator';

import { getLinkingConfig } from './linking-config';

export type LinkingContextType = {
    state?: string;
    options: LinkingOptions<AppNavigatorParamList>;
    getAndClearPendingURL: () => string | Route<string> | null;
    setPendingURL: (url: string | Route<string>) => void;
};

type LinkingContextProviderProps = PropsWithChildren<object>;

export const LinkingContext = React.createContext<LinkingContextType | undefined>(undefined);

export const navigationRef = createNavigationContainerRef<AppNavigatorParamList>();

/**
 * Will become something like `heltti:///`
 * The scheme is defined app.json:expo.scheme
 */
export const scheme = Linking.createURL('/');

/**
 * Only URLs matching these prefixes will be handled. The prefix will be stripped from the URL before parsing.
 * Read more: https://reactnavigation.org/docs/navigation-container/#linkingprefixes
 */
const prefixes = [
    scheme,
    'http://localhost:50001',
    'https://my.heltti.fi',
    'https://my.dev.heltti.fi',
    'https://my.staging.heltti.fi'
];

/**
 * To test links locally, try `xcrun simctl openurl booted <url>`, e.g.
 * xcrun simctl openurl booted heltti:///chats/Q2hhdE5vZGU6Mg==
 */
function LinkingContextProvider(props: LinkingContextProviderProps) {
    const { state } = useAuth();
    const { track } = useAnalytics();
    const { children } = props;

    const pendingUrl = useRef<string | null | Route<string>>(null);

    const getAndClearPendingURL = useCallback(() => {
        const url = pendingUrl.current;
        pendingUrl.current = null;

        return url;
    }, []);

    const wrappedGetStateFromPath = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (url: string, config: any) => {
            try {
                /**
                 * Resolve the link path. If the user is not authenticated, the config will not contain the desired
                 * path and link path components will not contain the queried path elements. In this case, queue
                 * the corresponding navigation action so that it can be executed after authentication (and any
                 * onboarding steps)
                 */
                const linkState = getStateFromPath(url, config) as PartialState<NavigationState>;

                if (!linkState) {
                    ErrorDiagnostics.log(`Capturing deep link url '${url}' pending because of authentication`);

                    pendingUrl.current = url;
                }

                track({ type: 'navigation', action: 'deep-link', detail1: `url=${url.toString()}` });

                return linkState;
            } catch (error: unknown) {
                ErrorDiagnostics.error(error);
            }
        },
        [track]
    );

    const setPendingURL = useCallback((url: string | Route<string>) => {
        pendingUrl.current = url;
    }, []);

    const value = useMemo(() => {
        return {
            options: {
                prefixes,
                config: getLinkingConfig(state === 'authenticated'),
                getStateFromPath: wrappedGetStateFromPath
            },
            getAndClearPendingURL,
            setPendingURL
        };
    }, [getAndClearPendingURL, state, wrappedGetStateFromPath, setPendingURL]);

    return <LinkingContext.Provider value={value}>{children}</LinkingContext.Provider>;
}

export const LinkingConsumer = LinkingContext.Consumer;
export const LinkingProvider = LinkingContextProvider;

export const useLinking = () => {
    const context = useContext(LinkingContext);
    if (!context) {
        throw Error('Cannot use context until it defined');
    }
    return context;
};
