import { RouteProp, StackActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { differenceInMinutes } from 'date-fns';
import {
    Calendar,
    createEventAsync,
    EntityTypes,
    getCalendarsAsync,
    getCalendarPermissionsAsync,
    requestCalendarPermissionsAsync
} from 'expo-calendar';
import * as WebBrowser from 'expo-web-browser';
import { Box, Flex, useBreakpointValue } from 'native-base';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import Animated from 'react-native-reanimated';
import styled from 'styled-components/native';

import { AppointmentContactInfo, AppointmentHeader, AppointmentLocation } from '~/components/appointment';
import { AppointmentVideoMeetingDetails } from '~/components/appointment/appointment-video-details';
import { ButtonSheet } from '~/components/button';
import { Card, CardDivider } from '~/components/card';
import { Color } from '~/components/color';
import { HeaderBackButton, HeaderCloseButton } from '~/components/header';
import { Shadow } from '~/components/shadow';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { UserInfo } from '~/components/user';
import { useAnalytics } from '~/contexts/analytics';
import { AppMessageId, FormattedMessage, useIntl } from '~/contexts/intl';
import { useCalendarEvent } from '~/hooks/calendar-event';
import { useDynamicHeaderColor } from '~/hooks/dynamic-header-color/dynamic-header-color';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';
import { Location, Maybe, User } from '~/types';
import { isWeb } from '~/utils';

import { AppointmentActionsModal } from './appointment-actions-modal';
import { AppointmentAddToCalendar } from './appointment-add-to-calendar';

export type AppointmentNavigation = StackNavigationProp<MainNavigatorParamList, 'appointment'>;

export type AppointmentRouteProp = RouteProp<MainNavigatorParamList, 'appointment' | 'appointment-confirmed'>;

const CardContainer = styled(Animated.View)<{ color: Color }>`
    background-color: ${({ color }) => color};
`;

function getProfileForLocale(acuteUser: { profile_fi?: string; profile_en?: string }, locale: string): string | null {
    if (!acuteUser) {
        return null;
    }

    return locale.toUpperCase() === 'FI' ? acuteUser.profile_fi ?? null : acuteUser.profile_en ?? null;
}

export function Appointment() {
    const { canGoBack, setOptions, pop, dispatch } = useNavigation<AppointmentNavigation>();
    const {
        name,
        params: { calendarEventId }
    } = useRoute<AppointmentRouteProp>();
    const { calendarEvent, cancelCalendarEvent } = useCalendarEvent(calendarEventId, 60 * 1000);
    const { start, end, cancelledAt, location, user, acuteUser, acuteCancelled } = calendarEvent ?? {};
    const { formatMessage, locale } = useIntl();
    const [displayActionsModal, setDisplayActionsModal] = useState<boolean>(false);
    const [calendars, setCalendars] = useState<Calendar[]>();
    const { track } = useAnalytics();

    const userFromAcuteUser = (acuteUserJson: string | undefined | null): User | null => {
        if (!acuteUserJson) {
            return null;
        }
        const parsedAcuteUser = JSON.parse(acuteUserJson);
        const profile = getProfileForLocale(parsedAcuteUser, locale);
        return {
            id: '',
            firstName: parsedAcuteUser.firstName,
            lastName: parsedAcuteUser.lastName,
            title: parsedAcuteUser.title || null,
            profile,
            avatarSmallUrl: parsedAcuteUser.avatarSmallUrl || null,
            avatarBigUrl: null
        };
    };

    const parsedAcuteUser = userFromAcuteUser(acuteUser);

    const displayUser = user ?? parsedAcuteUser;

    const color = name === 'appointment' ? Color.BACKGROUND_PRIMARY : Color.PRIMARY_DEFAULT;

    const desktopSize: boolean = useBreakpointValue({ base: false, md: true });

    useLayoutEffect(() => {
        setOptions({
            headerLeft: () =>
                name === 'appointment' ? (
                    <HeaderBackButton />
                ) : (
                    <HeaderCloseButton onPress={canGoBack() ? () => pop(3) : undefined} />
                ),
            headerTitle: formatMessage(cancelledAt ? 'appointment-cancelled.title' : (`${name}.title` as AppMessageId))
        });
    });

    const videoAppointmentDetails = calendarEvent?.videoAppointmentDetails;
    const calendarEventType = calendarEvent?.type;
    const { onScroll } = useDynamicHeaderColor({ range: 20, color: { from: color, to: Color.TEXT_TERTIARY } });

    const closeModal = useCallback(() => setDisplayActionsModal(false), []);

    const handleAddToCalendar = useCallback(
        async (calendar: Calendar) => {
            if (calendarEvent) {
                try {
                    const { firstName, lastName, title } = calendarEvent.user ?? {};
                    await createEventAsync(calendar.id, {
                        title:
                            formatMessage('appointment.add-calendar.event.title', { calendarEventType }) +
                            (firstName && lastName ? `: ${firstName} ${lastName}` : '') +
                            (title ? `, ${title}` : ''),
                        location: locationFullAddress(location),
                        startDate: start,
                        endDate: end
                        // TODO: url: deep link to the event
                    });
                } finally {
                    setCalendars(undefined);
                }
            }
        },
        [calendarEvent, calendarEventType, end, formatMessage, location, start]
    );

    const handleOpenCancellationTermsUrl = useCallback(async () => {
        const url = 'https://heltti.fi/wp-content/uploads/2025/03/myheltti-palvelun-yleiset-kayttoehdot-2025.pdf';
        await WebBrowser.openBrowserAsync(url);
    }, []);

    const getCalendars = useCallback(async () => {
        let { status } = await getCalendarPermissionsAsync();

        if (status === 'undetermined') {
            const permission = await requestCalendarPermissionsAsync();
            status = permission.status;

            track({ type: 'permission-calendar', action: status === 'granted' ? 'granted' : 'denied' });
        }

        if (status === 'granted') {
            return (await getCalendarsAsync(EntityTypes.EVENT)).filter(calendar => calendar.allowsModifications);
        }
        return [];
    }, [track]);

    const handleJoinVideoAppointment = useCallback(() => {
        if (videoAppointmentDetails) {
            dispatch(StackActions.replace('video-call', { url: videoAppointmentDetails.participantUrl }));
        }
    }, [dispatch, videoAppointmentDetails]);

    const cardStyle = isWeb() ? [{ borderRadius: 0 }] : [Shadow.styles.primary, { margin: Spacing.MEDIUM }];

    const containerStyle = isWeb() ? [Shadow.styles.primary, { margin: Spacing.MEDIUM }] : [];

    const content = calendarEvent ? (
        <>
            <Animated.ScrollView
                onScroll={onScroll}
                bounces
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={false}
            >
                <CardContainer color={color}>
                    <Card style={cardStyle}>
                        {start && end ? (
                            <AppointmentHeader
                                // TODO: sometimes this doesn't rerender after cancelation and still shows the appointment as not cancelled, check why.
                                task={cancelledAt ?? acuteCancelled ? 'view-cancellation' : 'view-appointment'}
                                date={calendarEvent.start}
                                type={calendarEventType}
                                duration={differenceInMinutes(end, start)}
                            />
                        ) : null}
                        {displayUser ? (
                            <Box w="100%">
                                <CardDivider />
                                <UserInfo image user={displayUser} />
                            </Box>
                        ) : null}
                        {calendarEvent.type === 'APPOINTMENT_VIDEO' ? (
                            <>
                                <CardDivider />
                                <AppointmentVideoMeetingDetails
                                    videoAppointmentUrl={videoAppointmentDetails?.participantUrl}
                                    onJoinVideoAppointment={handleJoinVideoAppointment}
                                />
                            </>
                        ) : location ? (
                            <>
                                <CardDivider />
                                <AppointmentLocation location={location} />
                            </>
                        ) : null}
                        <CardDivider />
                        {calendarEventType ? <AppointmentContactInfo type={calendarEventType} /> : null}
                    </Card>
                </CardContainer>
                {!cancelledAt ? (
                    <ButtonSheet
                        trailingSeparator={isWeb()}
                        carets
                        items={[
                            {
                                key: 'add-appointment-to-calendar',
                                label: formatMessage('appointment.add-calendar'),
                                onPress: async () => setCalendars(await getCalendars())
                            },
                            {
                                key: 'modify-appointment',
                                label: formatMessage('appointment.cancel'),
                                onPress: () => setDisplayActionsModal(true)
                            },
                            {
                                key: 'show-cancel-terms',
                                label: formatMessage('appointment.read-cancellation-terms'),
                                onPress: handleOpenCancellationTermsUrl
                            }
                        ]}
                    />
                ) : null}
            </Animated.ScrollView>
            <AppointmentAddToCalendar
                calendars={calendars}
                onAddToCalendar={handleAddToCalendar}
                onCancel={() => setCalendars(undefined)}
            />
            <AppointmentActionsModal
                calendarEvent={calendarEvent}
                visible={displayActionsModal}
                cancelCalendarEvent={cancelCalendarEvent}
                closeModal={closeModal}
            />
        </>
    ) : null;

    return isWeb() ? (
        <Flex display="flex" alignItems="center" bgColor={color} px={4}>
            {name === 'appointment-confirmed' && desktopSize && (
                <Flex width="100%" maxWidth={684} borderRadius={15} bg={Color.BACKGROUND_DEFAULT} p={4} mt={8}>
                    <Text.HEADER_3>
                        <FormattedMessage id="appointment-confirmed.title" />
                    </Text.HEADER_3>
                </Flex>
            )}
            <Flex
                direction="row"
                width="100%"
                maxWidth={684}
                pb={8}
                pt={4}
                bg={Color.BACKGROUND_DEFAULT}
                borderRadius={15}
                style={containerStyle}
            >
                {content}
            </Flex>
        </Flex>
    ) : (
        content
    );
}

function locationFullAddress(location?: Maybe<Location>) {
    if (location) {
        const { address1, address2, city, zip } = location;
        return (
            (address1 ? address1 : '') +
            (address2 ? ` ${address2}` : '') +
            (zip && city ? `, ${zip}` : '') +
            (city ? ` ${city}` : '')
        );
    }
}
