import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Sentry from '@sentry/react-native';
import { noCase } from 'change-case';
import { getIosPushNotificationServiceEnvironmentAsync } from 'expo-application';
import * as Clipboard from 'expo-clipboard';
import * as Linking from 'expo-linking';
import { getDevicePushTokenAsync } from 'expo-notifications';
import { AlertDialog, Input } from 'native-base';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ListRenderItemInfo, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useAsyncEffect from 'use-async-effect';

import { Alert } from '~/components/alert';
import { Button } from '~/components/button';
import { ArrowForwardOutlineIcon } from '~/components/icon';
import {
    List,
    ListItem,
    ListItemDescription,
    ListItemDivider,
    ListItemPane,
    ListItemPaneContent,
    ListItemTitle
} from '~/components/list';
import { ScreenContainer } from '~/components/screen';
import { Text } from '~/components/text';
import { useAppConfig } from '~/contexts/app-config';
import { useAuth } from '~/contexts/auth';
import { useNetworkServiceState } from '~/contexts/network-service-state';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { getDebugMode, isNative, isValidString, setDebugMode } from '~/utils';

type DeveloperNavigation = StackNavigationProp<AppNavigatorParamList>;

export const DeveloperOptionsScreenOptions = {
    title: 'Developer options',
    headerShown: true
};

const bool = (value: boolean | null) => (value === null ? '-' : value ? 'yes' : 'no');

const DeveloperOptionPushNotifications: React.FC = () => {
    const [token, setToken] = useState<string>('');
    const [environment, setEnvironment] = useState<string>('');

    useEffect(() => {
        (async () => {
            if (isNative()) {
                setToken((await getDevicePushTokenAsync()).data);
            }
            setEnvironment((await getIosPushNotificationServiceEnvironmentAsync()) ?? '');
        })();
    }, []);

    return (
        <ListItemPane>
            <ListItemTitle>Push notifications</ListItemTitle>
            <ListItemDescription>Device push token</ListItemDescription>
            <ListItemPaneContent>
                {isValidString(token) ? (
                    <TouchableOpacity
                        onPress={() =>
                            Alert.alert('Push notifications', 'Set push notifications state', [
                                {
                                    text: 'Open settings',
                                    onPress: async () => Linking.openSettings()
                                },
                                {
                                    text: 'Copy token',
                                    onPress: async () => Clipboard.setString(token)
                                },
                                { text: 'Cancel' }
                            ])
                        }
                    >
                        <Text.SYSTEM>{`${token}`}</Text.SYSTEM>
                    </TouchableOpacity>
                ) : (
                    <Text.SYSTEM>Not available on simulator</Text.SYSTEM>
                )}
                {isValidString(environment) ? <Text.P3>{environment}</Text.P3> : null}
            </ListItemPaneContent>
        </ListItemPane>
    );
};

const DeveloperOptionReachability: React.FC = () => {
    const { reachability, getAverageLatency } = useNetworkServiceState();
    const { config } = useAppConfig();
    return (
        <ListItemPane>
            <ListItemTitle>API reachability</ListItemTitle>
            <ListItemDescription>Backend API status and latency</ListItemDescription>
            <ListItemPaneContent>
                <Text.SYSTEM>
                    Service host: {config.host}
                    {'\n'}
                    Service state: {noCase((reachability as string) ?? '-')}
                    {'\n'}
                    Average latency: {getAverageLatency()?.toFixed(1)} ms
                </Text.SYSTEM>
            </ListItemPaneContent>
        </ListItemPane>
    );
};

const DeveloperOptionStorybook: React.FC = () => {
    const { navigate } = useNavigation<DeveloperNavigation>();
    return (
        <ListItem
            title="Storybook"
            description="View app components"
            accessory={() => <ArrowForwardOutlineIcon />}
            onPress={() => navigate('developer-storybook')}
        />
    );
};

const DeveloperOptionBiometricAuth: React.FC = () => {
    const { biometric } = useAuth();

    const methods = { 1: 'Touch ID', 2: 'Face ID', 3: 'Iris' };
    const typeNames = biometric.types.map(type => methods[type]).join(',');

    return (
        <ListItem
            title="Biometric authentication"
            description={`Available: ${bool(biometric.available)} (${typeNames}), enabled: ${bool(biometric.enabled)}`}
            onPress={() => {
                Alert.alert('Local authentication', 'Set local authentication state', [
                    {
                        text: 'Enable',
                        onPress: async () => biometric.enable()
                    },
                    {
                        text: 'Disable',
                        onPress: async () => biometric.disable()
                    },
                    {
                        text: 'Reset',
                        onPress: async () => biometric.reset()
                    },
                    { text: 'Cancel' }
                ]);
            }}
        />
    );
};

const DeveloperOptionPinAuth: React.FC = () => {
    const { pin } = useAuth();

    return (
        <ListItem
            title="PIN authentication"
            description={`Enabled: ${bool(pin.enabled)}`}
            onPress={() => {
                Alert.alert('PIN authentication', 'Set local PIN authentication state', [
                    {
                        text: 'Reset',
                        onPress: async () => pin.reset()
                    },
                    { text: 'Cancel' }
                ]);
            }}
        />
    );
};

const DeveloperUserIdentity: React.FC = () => {
    const { identity } = useAuth();
    const [username, setUsername] = useState<string | null>(null);

    useAsyncEffect(async () => setUsername(await identity.get()), []);

    return (
        <ListItem
            title="Current user"
            description={`Username: ${username ?? '-'}`}
            onPress={() => {
                if (username !== null) {
                    Alert.alert('Reset current user', `Reset current user (${username}) identity`, [
                        {
                            text: 'Reset',
                            onPress: async () => {
                                await identity.clear();
                                setUsername('-');
                            }
                        },
                        { text: 'Cancel' }
                    ]);
                }
            }}
        />
    );
};

const DeveloperOptionPasswordLogin: React.FC = () => {
    const { remote } = useAuth();

    const [visible, setVisible] = useState<boolean>(false);
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();

    const dialogRef = useRef(null);

    const handleAuthenticate = async () => {
        if (username?.length && password?.length) {
            try {
                const result = await remote.login(username, password);
                if (result.success) {
                    result.commit();
                } else {
                    Alert.alert(`Login failed: ${result.reason}`);
                }
            } finally {
                setVisible(false);
            }
        }
    };
    const handleClose = () => {
        setUsername(undefined);
        setPassword(undefined);
        setVisible(false);
    };

    return (
        <>
            <AlertDialog leastDestructiveRef={dialogRef} isOpen={visible} onClose={handleClose}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header>Enter credentials</AlertDialog.Header>
                    <AlertDialog.Body>
                        Please enter your username and password to authenticate
                        <Input
                            autoFocus
                            autoCorrect={false}
                            autoCapitalize="none"
                            placeholder="Username"
                            value={username}
                            defaultValue={username}
                            onChangeText={setUsername}
                            marginY={2}
                        />
                        <Input
                            autoCorrect={false}
                            autoCapitalize="none"
                            placeholder="Password"
                            type="password"
                            value={password}
                            defaultValue={password}
                            onChangeText={setPassword}
                            marginBottom={4}
                        />
                        <Button
                            type="primary"
                            label="Login"
                            disabled={!username || !password}
                            onPress={handleAuthenticate}
                        />
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>

            <ListItem
                title="Password authentication"
                description="Login with username and password"
                onPress={() => setVisible(true)}
            />
        </>
    );
};

const DeveloperOptionLocalStorage: React.FC = () => {
    const { navigate } = useNavigation<DeveloperNavigation>();
    return (
        <ListItem
            title="Local storage"
            description="View local storage items"
            accessory={() => <ArrowForwardOutlineIcon />}
            onPress={() => navigate('developer-local-storage')}
        />
    );
};

const DeveloperOptionDebugMode: React.FC = () => {
    const [debug, setDebug] = useState<boolean>(getDebugMode());

    useEffect(() => setDebugMode(debug), [debug]);

    return (
        <ListItem
            title="Debug mode"
            description={`Debug mode: ${bool(debug)}`}
            onPress={() =>
                Alert.alert('Debug', 'Enable debug mode?', [
                    { text: 'Enable', onPress: () => setDebug(true) },
                    { text: 'Disable', onPress: () => setDebug(false) },
                    { text: 'Cancel' }
                ])
            }
        />
    );
};

const DeveloperOptionSentry: React.FC = () => {
    return (
        <ListItem
            title="Sentry"
            description="Test Sentry.io integration"
            onPress={() =>
                Alert.alert('Sentry.io', 'Test capturing Sentry events', [
                    {
                        text: 'Generate JS error',
                        onPress: () => {
                            throw new Error('Sentry test');
                        }
                    },
                    { text: 'Generate native crash', onPress: () => Sentry.nativeCrash() },
                    { text: 'Cancel' }
                ])
            }
        />
    );
};

export const Developer: React.FC = () => {
    const { bottom } = useSafeAreaInsets();
    return (
        <ScreenContainer style={{ paddingBottom: bottom }}>
            <List<React.FC>
                data={
                    [
                        DeveloperOptionReachability,
                        isNative() ? DeveloperOptionPushNotifications : null,
                        DeveloperUserIdentity,
                        DeveloperOptionPasswordLogin,
                        DeveloperOptionPinAuth,
                        DeveloperOptionBiometricAuth,
                        DeveloperOptionLocalStorage,
                        DeveloperOptionStorybook,
                        DeveloperOptionDebugMode,
                        DeveloperOptionSentry
                    ].filter(item => !!item) as FunctionComponent[]
                }
                ItemSeparatorComponent={ListItemDivider}
                renderItem={({ item: Component }: ListRenderItemInfo<FunctionComponent>) => <Component />}
            />
        </ScreenContainer>
    );
};
