import { differenceInMinutes } from 'date-fns';
import React from 'react';
import { Pressable, View } from 'react-native';

import { AppointmentBubble } from '~/components/appointment/appointment-bubble';
import { CalendarEvent, LayoutStyleProps } from '~/types';

import { Spacing } from '../spacing';

export type CalendarEventListProps = {
    calendarEvents?: CalendarEvent[];
    onOpenCalendarEvent?: (calendarEvent: CalendarEvent) => void;
    layoutStyle?: LayoutStyleProps;
    isDisabled?: boolean;
};

export function CalendarEventList(props: CalendarEventListProps) {
    const { calendarEvents = [], onOpenCalendarEvent, layoutStyle, isDisabled = false } = props;

    const appointmentBubble = (calendarEvent: CalendarEvent) => (
        <AppointmentBubble
            key={calendarEvent.id}
            date={calendarEvent.start}
            contactType={calendarEvent.type}
            duration={differenceInMinutes(calendarEvent.end, calendarEvent.start)}
            staffName={
                calendarEvent.acuteStaffName ?? `${calendarEvent.user?.firstName} ${calendarEvent.user?.lastName}`
            }
            staffTitle={calendarEvent.acuteStaffTitle ?? calendarEvent.user?.title!}
            staffAvatarUrl={calendarEvent.user?.avatarSmallUrl!}
            isDisabled={isDisabled}
        />
    );

    return (
        <View style={layoutStyle}>
            {isDisabled
                ? calendarEvents.map((calendarEvent, index) => {
                      return calendarEvent.cancelledAt ? null : (
                          <>
                              {index > 0 && <Spacing.Vertical.SMALL />}
                              {appointmentBubble(calendarEvent)}
                          </>
                      );
                  })
                : calendarEvents.map((calendarEvent, index) => {
                      return calendarEvent.cancelledAt ? null : (
                          <Pressable key={calendarEvent.id} onPress={() => onOpenCalendarEvent?.(calendarEvent)}>
                              {index > 0 && <Spacing.Vertical.SMALL />}
                              {appointmentBubble(calendarEvent)}
                          </Pressable>
                      );
                  })}
        </View>
    );
}
