import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBreakpointValue, Flex } from 'native-base';
import React, { useCallback } from 'react';

import { Alert } from '~/components/alert';
import { Button } from '~/components/button';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { HeaderProfileEditButton } from '~/components/header';
import { ProfileCard } from '~/components/profile-card';
import { ProfileCardImage } from '~/components/profile-image';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { AuthContextType, useAuth } from '~/contexts/auth';
import { useIntl } from '~/contexts/intl';
import { useUserProfile } from '~/contexts/user-profile';
import { useExtendedNavigationHeader } from '~/hooks/navigation-header';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

import { ProfileButtonList } from './profile-button-list';

export type SignOutProps = {
    biometric: AuthContextType['biometric'];
    remote: AuthContextType['remote'];
    logoutFailure: string;
};

export async function profileSignOut(props: SignOutProps) {
    if (props.biometric.enabled) {
        await props.biometric.disable();
    }
    const result = await props.remote.logout();
    if (result.success) {
        result.commit();
    } else {
        Alert.alert(props.logoutFailure);
    }
}

export type ProfileNavigation = StackNavigationProp<MainNavigatorParamList, 'profile'>;

export function Profile() {
    const { navigate } = useNavigation<ProfileNavigation>();
    const { biometric, remote } = useAuth();
    const { formatMessage } = useIntl();

    const editProfileButton = <HeaderProfileEditButton onPress={() => navigate('profile-edit')} />;

    const web = useBreakpointValue({
        base: false,
        md: true
    });

    const { onScroll } = useExtendedNavigationHeader({
        headerRight: web ? <></> : editProfileButton
    });

    const profile = useUserProfile();

    const handleSignOut = useCallback(async () => {
        const logoutFailure = formatMessage('auth-logout.failure');
        const signOutProps: SignOutProps = {
            biometric,
            remote,
            logoutFailure
        };

        await profileSignOut(signOutProps);
    }, [biometric, remote, formatMessage]);

    return (
        <ContentScrollView
            showsVerticalScrollIndicator={false}
            onScroll={onScroll}
            bounces
            scrollEventThrottle={16}
            overScrollMode="never"
        >
            <ContentHorizontalMargins contentWidth="midsize" includePadding={false}>
                <Flex variant="standardFlexResponsive" grow="1" marginTop={{ base: '0px', md: '150px' }}>
                    <Flex direction="column">
                        <Flex>
                            <ProfileCardImage imageUrl={profile.avatarBigUrl ? profile.avatarBigUrl : undefined} />
                        </Flex>
                        {web && (
                            <Flex
                                direction="row"
                                justifyContent="center"
                                flexGrow={0}
                                flexShrink={0}
                                style={{ marginTop: 10 }}
                            >
                                {editProfileButton}
                            </Flex>
                        )}
                    </Flex>
                    <Flex flexGrow={2} marginTop={{ base: '24px', md: '0px' }}>
                        <ProfileCard {...profile} />

                        <ProfileButtonList />
                        {web && (
                            <>
                                <Spacing.Vertical.LARGE />
                                <Button
                                    fillContainer
                                    type="negative"
                                    size="regular"
                                    label={formatMessage('profile.sign-out')}
                                    onPress={handleSignOut}
                                    style={{ maxWidth: 204, marginLeft: 16 }}
                                />
                            </>
                        )}
                    </Flex>
                </Flex>
            </ContentHorizontalMargins>
        </ContentScrollView>
    );
}
