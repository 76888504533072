import { Flex } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { Image } from 'react-native';

import { Text } from '~/components/text';
import { useAppointmentsSelection } from '~/contexts/appointments-selection';
import { useIntl } from '~/contexts/intl';
import { useLoadingQuery } from '~/hooks/loading-query';
import {
    AppointmentContactType,
    AppointmentTimeOfDay,
    LocationFragment,
    LocationWithAppointmentTimeFragment,
    LocationsDocument,
    fragmentToLocation
} from '~/types';

import { Loader } from '../loader';

import { DefaultFormSelectRow, FormSelect } from './form-select';

type LocationSelectProps = {
    calendarReservationAccessId: string;
    initialLocation?: LocationFragment;
    regionId: string;
    date: string;
    contactTypes?: AppointmentContactType[];
    timeOfDay?: AppointmentTimeOfDay;
};

export const LocationWithAppointmentSelect: React.FC<LocationSelectProps> = props => {
    const { initialLocation, calendarReservationAccessId, date, regionId, contactTypes, timeOfDay } = props;
    const selection = useAppointmentsSelection();
    const { setLocation } = selection;
    const { formatMessage } = useIntl();
    const { locale } = useIntl();

    const { data, loading, error, refetch } = useLoadingQuery(LocationsDocument, {
        variables: {
            regionId,
            date,
            contactTypes,
            calendarReservationAccessId,
            timeOfDay
        }
    });

    useEffect(() => {
        refetch();
    }, [refetch, selection]);

    const allLocations = formatMessage('appointments.all-locations');

    const [selectedLocation, setSelectedLocation] = useState(initialLocation?.name);

    const findLocationByName = useCallback(
        (name: string): LocationWithAppointmentTimeFragment | undefined => {
            if (loading || error) {
                return;
            }

            const locations: LocationWithAppointmentTimeFragment[] | undefined =
                data?.root?.locationsWithAppointment?.map(fragmentToLocation);

            return locations?.find(_location => _location.name === name);
        },
        [data?.root?.locationsWithAppointment, error, loading]
    );

    const handleSelect = useCallback(
        (locationName: string) => {
            let _selectedLocation;

            if (locationName === allLocations) {
                setLocation(undefined);
                setSelectedLocation(allLocations);
            } else {
                _selectedLocation = findLocationByName(locationName);
                if (!_selectedLocation) {
                    return;
                }
                setLocation(_selectedLocation);
                setSelectedLocation(_selectedLocation.name);
            }
        },
        [allLocations, findLocationByName, setLocation]
    );

    const rowRenderer = useCallback(
        (value: string, index: number): React.ReactNode => {
            const location = findLocationByName(value);

            let time = null;

            if (location?.firstAppointmentTime) {
                const dateTimeFormat = new Intl.DateTimeFormat(locale, {
                    timeZone: 'Europe/Helsinki',
                    hour: 'numeric',
                    minute: 'numeric'
                });
                time = formatMessage('appointments.next-available-time', {
                    time: dateTimeFormat.format(new Date(location?.firstAppointmentTime))
                });
            }

            return (
                <DefaultFormSelectRow firstRow={index === 0}>
                    <Flex mr="10px" direction="row" alignItems="center">
                        <Image
                            resizeMode="cover"
                            source={require('~/assets/icons/heltti-icon.png')}
                            style={{ width: 24, height: 24 }}
                        />
                    </Flex>
                    <Flex>
                        <Text.SUBTITLE_1>{value}</Text.SUBTITLE_1>
                        <Text.SMALL_BUTTON_LABEL>{time}</Text.SMALL_BUTTON_LABEL>
                    </Flex>
                </DefaultFormSelectRow>
            );
        },
        [findLocationByName, formatMessage, locale]
    );

    if (loading) {
        return <Loader size="small" />;
    }

    if (error) {
        throw new Error(error.message); // TODO: better error handling
    }

    const options = (data?.root?.locationsWithAppointment ?? []).map(_location => _location.name);

    options.unshift(allLocations);

    return (
        <FormSelect
            options={options}
            defaultValue={selectedLocation ?? allLocations}
            rowRenderer={rowRenderer}
            onSelect={handleSelect}
        />
    );
};
