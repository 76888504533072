import { StackActions, useNavigation } from '@react-navigation/native';
import { Box } from 'native-base';
import React, { useCallback, useState } from 'react';

import { ButtonSheet } from '~/components/button';
import { CardDivider } from '~/components/card';
import { Color } from '~/components/color';
import { ModalPicker } from '~/components/modal-picker';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { CalendarEvent } from '~/types';
import { isWeb } from '~/utils';

import { AppointmentNavigation } from './appointment';
import { AppointmentCancel } from './appointment-cancel';
import { AppointmentModify } from './appointment-modify';

export type AppointmentActionsModalProps = {
    calendarEvent: CalendarEvent;
    cancelCalendarEvent: () => Promise<CalendarEvent | undefined>;
    visible: boolean;
    closeModal: () => void;
};

export function AppointmentActionsModal(props: AppointmentActionsModalProps) {
    const { calendarEvent, cancelCalendarEvent, visible, closeModal } = props;
    const { formatMessage } = useIntl();

    const { dispatch } = useNavigation<AppointmentNavigation>();

    const [modifyInProgress, setModifyInProgress] = useState<boolean>(false);
    const [cancelInProgress, setCancelInProgress] = useState<boolean>(false);

    const openModify = useCallback(() => {
        setModifyInProgress(true);
    }, []);

    const openCancel = useCallback(() => {
        setCancelInProgress(true);
    }, []);

    const handleModifyCalendarEvent = useCallback(async () => {
        try {
            if (calendarEvent?.id && calendarEvent?.calendarReservationAccess?.id) {
                dispatch(
                    StackActions.replace('appointments', {
                        calendarReservationAccessId: calendarEvent.calendarReservationAccess.id,
                        calendarEventId: calendarEvent.id
                    })
                );
            }
        } finally {
            setModifyInProgress(false);
        }
    }, [calendarEvent, dispatch]);

    const handleCancelCalendarEvent = useCallback(async () => {
        try {
            await cancelCalendarEvent();
        } finally {
            setCancelInProgress(false);
            closeModal();
        }
    }, [cancelCalendarEvent, closeModal]);

    return (
        <ModalPicker
            visible={visible}
            bottomPadding={false}
            extraStyles={
                isWeb()
                    ? {
                          width: 343,
                          marginLeft: 'auto',
                          marginRight: 'auto'
                      }
                    : undefined
            }
            renderContent={() => (
                <>
                    <Box w="100%">
                        <Text.PARAGRAPH_3
                            after={4}
                            style={{ color: Color.SMOOTHIE, marginBottom: 10, marginTop: 9, textAlign: 'center' }}
                        >
                            <FormattedMessage id="appointment.cancel" />
                        </Text.PARAGRAPH_3>
                        <Text.CAPTION style={{ textAlign: 'center' }}>
                            {formatMessage('appointment.modify.cancellation-hours')}
                        </Text.CAPTION>
                        <Text.CAPTION style={{ marginBottom: 22, textAlign: 'center' }}>
                            {formatMessage('appointment.modify.new-appointment-after-cancellation')}
                        </Text.CAPTION>
                        <CardDivider />
                        <ButtonSheet
                            trailingSeparator={false}
                            carets
                            items={[
                                // Disable modifications until Acute issue is resolved
                                // {
                                //     key: 'modify-appointment',
                                //     label: formatMessage('appointment.modify'),
                                //     onPress: openModify
                                // },
                                {
                                    key: 'show-cancel-terms',
                                    label: formatMessage('appointment.cancel'),
                                    onPress: openCancel,
                                    color: Color.WARNING_DEFAULT
                                }
                            ]}
                        />
                    </Box>
                    <AppointmentModify
                        visible={modifyInProgress}
                        modifiable={calendarEvent.isModifiable ?? false}
                        onModifyCalendarEvent={handleModifyCalendarEvent}
                        onCancel={() => setModifyInProgress(false)}
                    />
                    <AppointmentCancel
                        visible={cancelInProgress}
                        onCancelCalendarEvent={handleCancelCalendarEvent}
                        onCancel={() => setCancelInProgress(false)}
                    />
                </>
            )}
            renderActions={() => null}
            onCancel={closeModal}
        />
    );
}
